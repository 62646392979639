<template>
  <div class="carousel-container">
    <EmblaCarouselVue class="embla" :options="{ loop: false }">
      <div class="embla__viewport" ref="viewport">
        <div class="embla__container">
          <div v-for="(image, index) in images" :key="index" class="embla__slide">
            <img :src="image.url" class="embla__slide__img" :alt="'Image ' + (index + 1)" />
          </div>
        </div>
      </div>

      <!-- ปุ่ม Prev และ Next เป็นไอคอนลูกศรในวงกลม -->
      <button @click="scrollPrev" class="carousel-control prev">‹</button>
      <button @click="scrollNext" class="carousel-control next">›</button>

      <!-- Thumbnails สำหรับ pagination -->
      <div class="carousel-thumbnails">
        <img
          v-for="(image, index) in images"
          :key="index"
          :src="image.url"
          class="thumbnail"
          :class="{ 'is-active': selectedIndex === index }"
          @click="scrollToSlide(index)"
          :alt="'Thumbnail ' + (index + 1)"
        />
      </div>
    </EmblaCarouselVue>
  </div>
</template>

<script>
import EmblaCarousel from 'embla-carousel';
import { ref, onMounted } from 'vue';

export default {
  name: 'PropertyDetailImg',
  props: {
    images: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const viewport = ref(null);
    const embla = ref(null);
    const selectedIndex = ref(0);

    const scrollToSlide = (index) => {
      if (embla.value) {
        embla.value.scrollTo(index);
      }
    };

    const scrollPrev = () => {
      if (embla.value) {
        embla.value.scrollPrev();
      }
    };

    const scrollNext = () => {
      if (embla.value) {
        embla.value.scrollNext();
      }
    };

    onMounted(() => {
      if (viewport.value) {
        embla.value = EmblaCarousel(viewport.value, { loop: false });

        if (embla.value && embla.value.on) {
          embla.value.on('select', () => {
            selectedIndex.value = embla.value.selectedScrollSnap();
          });
        }
      }
    });

    return { viewport, embla, scrollToSlide, scrollPrev, scrollNext, selectedIndex };
  },
};
</script>

<style scoped>
.carousel-container {
  position: relative;
  width: 100%;
  max-width: 900px;
  margin: auto;
}

.embla__viewport {
  overflow: hidden;
  width: 85%; /* ลดขนาด viewport และจัดกึ่งกลาง */
  margin: auto; /* จัดกึ่งกลาง */
}

.embla__container {
  display: flex;
}

.embla__slide {
  min-width: 100%;
  padding: 10px;
}

.embla__slide__img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.carousel-thumbnails {
  display: flex;
  justify-content: center;
  gap: 5px;
  margin-top: 15px;
}

.thumbnail {
  width: 60px;
  height: 40px;
  object-fit: cover;
  cursor: pointer;
  border: 2px solid transparent;
  opacity: 0.5; /* ทำให้ thumbnail ที่ไม่ได้เลือกจางลง */
  transition: opacity 0.3s; /* เพิ่มการเปลี่ยนแปลงความจาง */
}

.thumbnail.is-active {
  border-color: #2d1f65;
  opacity: 1; /* ทำให้ thumbnail ที่เลือกอยู่ชัดเจน */
}

.carousel-control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #2d1f65; /* สีพื้นหลังวงกลม */
  color: white;
  border: none;
  border-radius: 50%; /* ทำให้เป็นวงกลม */
  width: 50px; /* เพิ่มขนาดความกว้างของปุ่ม */
  height: 50px; /* เพิ่มขนาดความสูงของปุ่ม */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px; /* เพิ่มขนาดของไอคอนลูกศร */
  cursor: pointer;
  z-index: 1;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3); /* เพิ่มเงา */
}

.prev {
  left: 10px;
}

.next {
  right: 10px;
}
</style>
