// src/axios.js
import axios from 'axios';
import store from '@/store';

const BASE_URL = process.env.VUE_APP_API_URL;

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});

axiosInstance.interceptors.response.use(
    response => response,
    async error => {
      const originalRequest = error.config;
  
      if (
        error.response &&
        error.response.status === 401 &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;
  
        try {
          await axiosInstance.post('/auth/refresh-token');
          return axiosInstance(originalRequest);
        } catch (refreshError) {
          const wasLoggedIn = store.getters.isLoggedIn;
  
          // อัปเดต Vuex Store
          store.commit('setLoggedIn', false);
          store.commit('setUserId', null);
          store.commit('setUserName', '');
  
          // ตั้งค่า sessionExpired เป็น true เฉพาะเมื่อผู้ใช้เคยล็อกอิน
          if (wasLoggedIn) {
            store.commit('setSessionExpired', true);
          }
  
          return Promise.reject(refreshError);
        }
      }
  
      return Promise.reject(error);
    }
  );

export default axiosInstance;
