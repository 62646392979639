<template>
    <div class="container-xxl py-5">
            <div class="container">
                <div class="text-center mx-auto mb-5 wow animate__fadeInUp" data-wow-delay="0.3s" style="max-width: 600px;">
                    <h1 class="mb-3">ประเภทอสังหาริมทรัพย์</h1>
                    <p>เลือกค้นหาประเภทอสังหาริมทรัพย์ที่คุณสนใจทันทีได้ในคลิกเดียว</p>
                </div>
                <div class="row g-4">
                    <div class="col-6 col-md-4 wow animate__animated animate__fadeInUp" data-wow-delay="0.3s">
                        <a
                            class="cat-item d-block bg-light text-center rounded p-3"
                            href="#"
                            @click.prevent="filterByType('ที่ดิน')"
                        >
                            <div class="rounded p-4">
                                <div class="icon mb-3">
                                    <img class="img-fluid" src="img/land.png" alt="Icon">
                                </div>
                                <h5>ที่ดิน</h5>
                            </div>
                        </a>
                    </div>

                    <div class="col-6 col-md-4 wow animate__animated animate__fadeInUp" data-wow-delay="0.3s">
                        <a
                            class="cat-item d-block bg-light text-center rounded p-3"
                            href="#"
                            @click.prevent="filterByType('บ้าน')"
                        >
                            <div class="rounded p-4">
                                <div class="icon mb-3">
                                    <img class="img-fluid" src="img/house.png" alt="Icon">
                                </div>
                                <h5>บ้าน</h5>
                            </div>
                        </a>
                    </div>

                    <div class="col-6 col-md-4 wow animate__animated animate__fadeInUp" data-wow-delay="0.3s">
                        <a
                            class="cat-item d-block bg-light text-center rounded p-3"
                            href="#"
                            @click.prevent="filterByType('คอนโด')"
                        >
                            <div class="rounded p-4">
                                <div class="icon mb-3">
                                    <img class="img-fluid" src="img/condo.png" alt="Icon">
                                </div>
                                <h5>คอนโด</h5>
                            </div>
                        </a>
                    </div>

                    <div class="col-6 col-md-4 wow animate__animated animate__fadeInUp" data-wow-delay="0.3s">
                        <a
                            class="cat-item d-block bg-light text-center rounded p-3"
                            href="#"
                            @click.prevent="filterByType('ทาวน์โฮม')"
                        >
                            <div class="rounded p-4">
                                <div class="icon mb-3">
                                    <img class="img-fluid" src="img/townhome.png" alt="Icon">
                                </div>
                                <h5>ทาวน์โฮม</h5>
                            </div>
                        </a>
                    </div>

                    <div class="col-6 col-md-4 wow animate__animated animate__fadeInUp" data-wow-delay="0.3s">
                        <a
                            class="cat-item d-block bg-light text-center rounded p-3"
                            href="#"
                            @click.prevent="filterByType('อาคารพาณิชย์')"
                        >
                            <div class="rounded p-4">
                                <div class="icon mb-3">
                                    <img class="img-fluid" src="img/commercial-building.png" alt="Icon">
                                </div>
                                <h5>อาคารพาณิชย์</h5>
                            </div>
                        </a>
                    </div>

                    <div class="col-6 col-md-4 wow animate__animated animate__fadeInUp" data-wow-delay="0.3s">
                        <a
                            class="cat-item d-block bg-light text-center rounded p-3"
                            href="#"
                            @click.prevent="filterByType('อสังหาอื่นๆ')"
                        >
                            <div class="rounded p-4">
                                <div class="icon mb-3">
                                    <img class="img-fluid" src="img/other.png" alt="Icon">
                                </div>
                                <h5>อสังหาริมทรัพย์อื่น ๆ</h5>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
  </template>
  
  <script>

import { WOW } from 'wowjs';
import 'animate.css';

  export default {
    data() {
      return {
        expanded: false,
      }
    },
    computed: {
      containerStyle() {
        return {
          maxHeight: this.expanded ? 'none' : '300px',
          transition: 'max-height 0.3s ease-in-out'
        }
      }
    },
    methods: {
        toggleExpand() {
            this.expanded = !this.expanded
        },
        filterByType(type) {
            this.$router.push({ name: 'PropertyListPage', query: { category: type } });
        },
    },
    mounted() {
        new WOW({
            boxClass: 'wow',
            animateClass: 'animate__animated',
            offset: 0,
            mobile: true,
            live: true
        }).init();
    }
  }
  </script>
  
  <style scoped>
  
  @import 'animate.css';
    .category-list {
      position: relative;
    }
    .overflow-hidden {
      overflow: hidden;
    }

    .cat-item h5 {
        color: #8A2BE2;
    }

    .cat-item span {
        color: #8A2BE2; /* สีม่วงแกมน้ำเงิน */
    }

    .cat-item {
        text-decoration: none; /* เอาเส้นใต้ออก */
    }
    .cat-color-size {
        color: #2d1f65;
    }
    .cat-item .icon img {
        width: 50px; /* กำหนดขนาดความกว้าง */
        height: 50px; /* กำหนดขนาดความสูง */
        object-fit: contain; /* รักษาสัดส่วนภาพ */
    }

    /* ปรับขนาดฟอนต์และจัดข้อความให้อยู่ในกรอบบนหน้าจอขนาดเล็ก */
@media (max-width: 768px) { 
    .cat-item h5 {
        font-size: 14px; /* ลดขนาดฟอนต์ในหน้าจอขนาดเล็ก */
        word-wrap: break-word; /* ทำให้ข้อความย้ายบรรทัดเมื่อยาวเกินไป */
    }

    .cat-item .icon img {
        width: 40px; /* ปรับขนาดไอคอนให้เล็กลง */
        height: 40px;
    }

    .cat-item {
        padding: 10px; /* ลด padding ในหน้าจอขนาดเล็ก */
    }

    .cat-item .icon img {
        width: 80px; /* กำหนดขนาดความกว้าง */
        height: 80px; /* กำหนดขนาดความสูง */
        object-fit: contain; /* รักษาสัดส่วนภาพ */
    }
}

@media (max-width: 576px) {
    .cat-item h5 {
        font-size: 12px; /* ปรับขนาดฟอนต์เล็กลงสำหรับหน้าจอที่เล็กที่สุด */
    }
    
    .cat-item {
        padding: 8px; /* ลด padding เพิ่มเติมในหน้าจอขนาดเล็ก */
    }

    .cat-item .icon img {
        width: 35px; /* ปรับขนาดไอคอนให้เหมาะสมกับหน้าจอเล็ก */
        height: 35px;
    }
}

  </style>