<!-- NavbarTest.vue -->
<template>
  <div class="fixnavbar fixed-top">
    <!-- Navbar หลัก -->
    <nav class="navbar navbar-expand-lg navbar-dark bg-nav">
      <div class="container">
        <router-link to="/" class="navbar-brand">
          <img src="../../public/img/logoOnly.png" alt="Logo" style="height: 40px;">
          Kai Zub
        </router-link>
        <!-- เมนูหลัก -->
        <ul class="navbar-nav me-auto mb-2 mb-lg-0 d-none d-lg-flex">
          <li class="nav-item">
            <router-link to="/propertyList" class="nav-link">หน้าประกาศขาย</router-link>
          </li>
          <li class="nav-item">
            <!-- <router-link to="/cart" class="nav-link">Cart</router-link> -->
          </li>
        </ul>
        <!-- เมนูผู้ใช้ -->
        <ul class="navbar-nav d-none d-lg-flex">
          <li class="nav-item" v-if="!isLoggedIn">
            <LoginModal />
          </li>
          <span v-if="isLoggedIn" class="me-2 d-flex align-items-center text-white">
            {{ credit }}
            <img
              src="img/coin-img.png"
              alt="coin"
              class="rounded-circle ms-2"
              style="width: 30px; height: 30px; object-fit: cover;"
            />
          </span>
          <li v-if="isLoggedIn" class="nav-item dropdown d-flex align-items-center">
            <a
              class="nav-link dropdown-toggle d-flex align-items-center"
              href="#"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src="https://static.thairath.co.th/media/dFQROr7oWzulq5Fa5nRRVgnzYSSwUoPM7rigVHaj4QhdURLfyt90hBPNzf89n8vZ5bp.jpg"
                alt="Profile"
                class="rounded-circle me-2"
                style="width: 30px; height: 30px; object-fit: cover;"
              />
              {{ userName }}
            </a>
            <ul class="dropdown-menu bg-nav" aria-labelledby="navbarDropdown">
              <li>
                <a
                  href="#"
                  class="dropdown-item"
                  :class="{ active: $route.path === '/dashboardPage' && $route.query.component === 'UserInfoPage' }"
                  @click.prevent="navigateToEditUser"
                >
                  ข้อมูลผู้ใช้
                </a>
              </li>
              <li><hr class="dropdown-divider" /></li>
              <li>
                <a
                  href="#"
                  class="dropdown-item"
                  :class="{ active: $route.path === '/dashboardPage' && $route.query.component === 'ListProperty' }"
                  @click.prevent="navigateToDashboard"
                >
                  จัดการหน้าประกาศ
                </a>
              </li>
              <li><hr class="dropdown-divider" /></li>
              <li>
                <a href="#" class="dropdown-item" @click.prevent="handleLogout">Logout</a>
              </li>
            </ul>
          </li>
          <li class="nav-item ms-3">
          <div v-if="isLoggedIn">
            <button class="btn btn-outline-light" @click="navigateToAddPost">ลงประกาศขาย</button>
          </div>
          <div v-else>
            <LoginModal :buttonLabel="'ลงประกาศขาย'" />
          </div>
          </li>
        </ul>
        <!-- ปุ่ม Toggler สำหรับ Mobile -->
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasNavbar"
          aria-controls="offcanvasNavbar"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>
    </nav>
    <!-- Navbar ย่อย (เมนูย่อย) -->
    <nav class="navbar navbar-expand-lg navbar-light bg-white" id="submenu">
      <div class="container">
        <div class="collapse navbar-collapse">
          <ul class="navbar-nav">
            <li class="nav-item">
              <a class="nav-link" :class="{ active: $route.path === '/' }" @click.prevent="navigateTo('/')" aria-current="page" href="#">หน้าแรก</a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                href="#"
                :class="{ active: $route.path.includes('propertyList') && $route.query.adType === 'ขาย' }"
                @click.prevent="filterByType('ขาย')"
              >
                ขาย
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                href="#"
                :class="{ active: $route.path.includes('propertyList') && $route.query.adType === 'เช่า' }"
                @click.prevent="filterByType('เช่า')"
              >
                เช่า
              </a>
            </li>
            <!-- <li class="nav-item">
              <a class="nav-link" href="#">ข้อมูลแพ็กเกจโฆษณา</a>
            </li> -->
            <li class="nav-item">
              <a class="nav-link" :class="{ active: $route.path === '/contact' }" @click.prevent="navigateTo('/contact')" href="#">ติดต่อเรา</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <!-- Offcanvas Menu สำหรับ Mobile -->
    <div
      class="offcanvas offcanvas-end offcanvas-custom"
      tabindex="-1"
      id="offcanvasNavbar"
      aria-labelledby="offcanvasNavbarLabel"
    >
      <div class="offcanvas-header">
        <h5 class="offcanvas-title text-white" id="offcanvasNavbarLabel">เมนู</h5>
        <button
          type="button"
          class="btn-close btn-close-white"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div class="offcanvas-body">
        <!-- เมนูหลัก -->
        <ul class="navbar-nav mb-2 mb-lg-0">
          
          <li class="nav-item mt-2" data-bs-dismiss="offcanvas" v-if="!isLoggedIn">
            <LoginModal />
          </li>
          <li class="nav-item" v-else>
              <img
                src="https://static.thairath.co.th/media/dFQROr7oWzulq5Fa5nRRVgnzYSSwUoPM7rigVHaj4QhdURLfyt90hBPNzf89n8vZ5bp.jpg"
                alt="Profile"
                class="rounded-circle"
                style="width: 35px; height: 35px; object-fit: cover;"
              />
              {{ userName }} <!-- แสดงชื่อผู้ใช้ที่ดึงจาก Vuex -->
              <span v-if="isLoggedIn" class="text-center d-flex align-items-center justify-content-center text-white mt-3">
                {{ credit }}
                <img
                  src="img/coin-img.png"
                  alt="coin"
                  class="rounded-circle ms-2"
                  style="width: 30px; height: 30px; object-fit: cover;"
                />
              </span>
          </li>
        </ul>
        <!-- เมนูย่อย -->
        <hr class="text-white" />
        <ul class="navbar-nav">
          
          <li class="nav-item">
            <a
              class="nav-link"
              href="#"
              @click.prevent="navigateTo('/')"
              data-bs-dismiss="offcanvas"
            >
              หน้าแรก
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="#"
              @click.prevent="navigateTo('/propertyList')"
              data-bs-dismiss="offcanvas"
            >
              หน้าประกาศขาย
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link active"
              href="#"
              @click.prevent="filterByType('ขาย')"
              data-bs-dismiss="offcanvas"
            >
              ขาย
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="#"
              @click.prevent="filterByType('เช่า')"
              data-bs-dismiss="offcanvas"
            >
              เช่า
            </a>
          </li>
          <!-- <li class="nav-item">
            <a
              class="nav-link"
              href="#"
              @click.prevent="navigateTo('/packageInfo')"
              data-bs-dismiss="offcanvas"
            >
              ข้อมูลแพ็กเกจโฆษณา
            </a>
          </li> -->
          <li class="nav-item">
            <a
              class="nav-link"
              href="#"
              @click.prevent="navigateTo('/contact')"
              data-bs-dismiss="offcanvas"
            >
              ติดต่อเรา
            </a>
          </li>
        </ul>
        <!-- เมนูผู้ใช้ -->
        <hr class="text-white" />
        <ul class="navbar-nav">
          <li class="nav-item mt-2">
            <!-- <router-link to="/profile" class="dropdown-item">ข้อมูลผู้ใช้</router-link> -->
            <button
              class="nav-link"
              href="#"
              @click.prevent="navigateToEditUser"
              data-bs-dismiss="offcanvas"
            >
              ข้อมูลผู้ใช้
            </button>
          </li>
          <li class="nav-item mt-2">
            <a
              class="nav-link"
              href="#"
              @click.prevent="navigateToDashboard"
              data-bs-dismiss="offcanvas"
            >
              จัดการหน้าประกาศ
            </a>
          </li>
          <li>
            <hr class="dropdown-divider" />
          </li>
          <li class="nav-item mt-2">
            <button
              class="btn btn-outline-light w-100"
              @click="navigateToAddPost"
              data-bs-dismiss="offcanvas"
            >
              ลงประกาศขาย
            </button>
          </li>
          <li class="nav-item mt-2">
            <button
              class="btn btn-outline-light w-100"
              @click.prevent="handleLogout"
              data-bs-dismiss="offcanvas"
            >
            Logout
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import LoginModal from '@/views/LoginModal.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      // username: 'Usename',
    };
  },
  name: 'NavbarTest',
  components: {
    LoginModal,
  },
  methods: {
    ...mapActions(['logout']),
    async handleLogout() {
      try {
        await this.logout();
        this.$router.push('/');
      } catch (error) {
        console.error('Logout failed:', error);
      }
    },
    navigateTo(route) {
      this.$router.push(route);
    },
    filterByType(type) {
      this.$router.push({ name: 'PropertyListPage', query: { adType: type } });
    },
    navigateToAddPost() {
      this.$router.push({ name: 'DashboardPage', query: { component: 'AddProperty2' } });
    },
    navigateToEditUser() {
      this.$router.push({ name: 'DashboardPage', query: { component: 'UserInfoPage' } });
    },
    navigateToDashboard() {
      this.$router.push({ name: 'DashboardPage', query: { component: 'ListProperty' } });
    },
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'userName', 'credit']), // ดึง userName มาจาก Vuex
  },
};
</script>

<style scoped>
.fixnavbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

ul.navbar-nav {
  justify-content: center;
  align-items: center;
}

.bg-nav {
  background-color: #2d1f65;
}

/* ซ่อนเมนูหลักและเมนูผู้ใช้บน Mobile */
@media (max-width: 991.98px) {
  .navbar-nav.d-none.d-lg-flex {
    display: none !important;
  }
}

/* ปรับความสูงของ Navbar ย่อย */
#submenu .navbar-nav .nav-link {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

#submenu {
  height: 56px;
}

#submenu .navbar-nav .nav-link {
  line-height: 56px;
}

/* เพิ่มระยะห่างระหว่างปุ่มใน Offcanvas Menu */
.offcanvas .btn {
  margin-top: 10px;
}

/* ตั้งค่าสีพื้นหลังของ Offcanvas Menu */
.offcanvas-custom {
  background-color: #2d1f65;
  color: white;
}

/* ปรับสีของเส้นคั่น */
.offcanvas-custom hr {
  border-color: rgba(255, 255, 255, 0.1);
}

/* ปรับสีของลิงก์ใน Offcanvas Menu */
.offcanvas-custom .nav-link {
  color: white;
}

.offcanvas-custom .nav-link:hover {
  color: #ddd;
}
hr {
  border: none; /* ลบเส้นขอบมาตรฐาน */
  height: 1px; /* ความหนาของเส้น */
  background-color: white; /* สีเข้มของเส้น */
  opacity: 1; /* ความทึบ 1 คือทึบเต็มที่ */
}
.dropdown-item {
  color: white !important;  /* เปลี่ยนตัวหนังสือใน dropdown เป็นสีขาว */
}

.dropdown-item:hover {
  background-color: #6c757d70; /* ปรับสีพื้นหลังเมื่อ hover */
}
.dropdown-item.active,
.dropdown-item:active {
  background-color: #6c757d70 !important; /* ใช้สีที่เหมาะสม (อาจเป็นสีเทาแบบโปร่ง) */
  color: white !important; /* ปรับสีตัวอักษรให้ตรงกัน */
}
</style>