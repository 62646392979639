<template>
  <div class="top-nav d-flex flex-column flex-sm-row justify-content-between align-items-center py-2 mb-3 ms-0 ms-md-3">
    <h5>รายการประกาศทั้งหมด</h5>
    <div class="filters d-flex">
      <select
          class="form-select me-2"
          style="border-color: #2d1f65;"
          v-model="searchFilters.Category"
          @change="updateFilters"
      >
          <option value="">ประเภทอสังหา</option>
          <option v-for="category in categories" :key="category" :value="category">
              {{ category }}
          </option>
      </select>
      <select
          class="form-select"
          style="border-color: #2d1f65;"
          v-model="searchFilters.PropertyType"
          @change="updateFilters"
      >
          <option value="">ประเภทประกาศ</option>
          <option
              v-for="propertyType in propertyTypes"
              :key="propertyType"
              :value="propertyType"
          >
              {{ propertyType }}
          </option>
      </select>
    </div>
  </div>
  <!-- List of Items -->
  <div class="item-list">
    <div class="card shadow mb-3" style="border-color: #2d1f65;" v-for="item in paginatedItems" :key="item.id">
      <div class="row g-0">
        <div class="col-md-3 text-start">
          <img :src="item.image" class="img-fluid rounded-start">
        </div>
        <div class="col-md-9">
          <div class="card-body">
            <h5 class="card-title text-center text-md-start text-custom">{{ item.title }}</h5>
            <div class="badges mb-2 text-center text-md-start mt-3 mb-3">
              <span v-if="item.SelectedCard === 'exclusive'" class="badge b-m me-2" style="background-color: #efbf04;">Exclusive</span>
              <span v-if="item.SelectedCard === 'premium'" class="badge b-m me-2" style="background-color: #310bd8;">Premium</span>
              <span v-if="item.SelectedCard === 'hot'" class="badge b-m me-2" style="background-color: #c92a0d;">HOT</span>
              <span v-if="item.SelectedCard === 'standard'" class="badge b-m me-2" style="background-color: gray;">Standard</span>
              <span class="badge badge-green-custom me-2">{{ item.propertyType }}</span>
              <span class="badge badge-red-custom me-2">{{ item.category }}</span>
              <span class="badge badge-purple-custom me-2">{{ item.rai }}-{{ item.ngan }}-{{ item.wah }} ไร่</span>
            </div>
            <div class="card-footer border-top-none d-flex justify-content-between align-items-center m-3">
              <span class="price">{{ item.price }}</span>
              <div class="button-group">
                <button class="btn custom-outline-btn me-2">ดูสถิติประกาศ</button>
                <button class="btn custom-outline-btn me-2" @click="goToEditProperty(item.id)">แก้ไข</button>
                <button class="btn custom-outline-btn me-2" @click="openCloseModal(item.id)">ปิดประกาศ</button>
                <button v-if="item.SelectedCard !== 'exclusive'" class="btn custom-outline-btn" @click="bumpProperty(item.id)">เลื่อนประกาศ</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="pagination-controls d-flex justify-content-center my-3 mt-5" v-if="totalPages > 1">
    <button
      class="btn custom-outline-btn me-2"
      :disabled="currentPage === 1"
      @click="previousPage"
    >
      ก่อนหน้า
    </button>
    <span class="d-flex align-items-center" style="color: #2d1f65;">หน้า {{ currentPage }} จาก {{ totalPages }}</span>
    <button
      class="btn custom-outline-btn ms-2"
      :disabled="currentPage === totalPages"
      @click="nextPage"
    >
      ถัดไป
    </button>
  </div>

  <!-- Modal สำหรับปิดประกาศ -->
  <teleport to="body">
    <!-- Backdrop -->
    <transition name="backdrop">
      <div v-if="showModal" class="modal-backdrop fade show" @click="closeModal"></div>
    </transition>
    <!-- Modal -->
    <transition name="modal">
      <div v-if="showModal" class="modal fade show" tabindex="-1" role="dialog" style="display: block;">
        <div class="modal-dialog modal-dialog-centered custom-modal-dialog" role="document">
          <div class="modal-content custom-modal-content">
            <!-- Modal Header -->
            <div class="modal-header">
              <h5 class="modal-title p-2 mx-auto">ปิดประกาศ</h5>
              <button type="button" class="btn-close" @click="closeModal" style="position: absolute; right: 20px; top: 20px;"></button>
            </div>
            <!-- Modal Body -->
            <div class="modal-body mx-auto">
              <p>กรุณาเลือกเหตุผลในการปิดประกาศ:</p>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="closeReason" id="sold" value="sold" v-model="closeReason">
                <label class="form-check-label" for="sold">
                  ทรัพย์ถูกขายแล้ว
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="closeReason" id="cancelled" value="cancelled" v-model="closeReason">
                <label class="form-check-label" for="cancelled">
                  ยกเลิกประกาศ
                </label>
              </div>
            </div>
            <!-- Modal Footer -->
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" @click="closeModal">ยกเลิก</button>
              <button type="button" class="btn btn-primary" @click="confirmClose">ยืนยัน</button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </teleport>
  <teleport to="body">
    <transition name="backdrop">
      <div v-if="showNotification" class="modal-backdrop fade show"></div>
    </transition>
    <transition name="modal">
      <div v-if="showNotification" class="modal fade show" tabindex="-1" role="dialog" style="display: block;">
        <div class="modal-dialog modal-dialog-centered custom-modal-dialog" role="document">
          <div class="modal-content custom-modal-content">
            <div class="modal-header">
              <div class="mx-auto">
                <div class="modal-body p-2" v-if="isSuccess">
                  <div class="col-8 mx-auto">
                    <svg
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 131 131"
                      width="100%"
                      height="100%" 
                    >
                      <circle
                        class="circle"
                        fill="none"
                        stroke="#198754"
                        stroke-width="6"
                        stroke-miterlimit="10"
                        cx="65.1"
                        cy="65.1"
                        r="62.1"
                      />
                      <polyline
                        class="check"
                        fill="none"
                        stroke="#198754"
                        stroke-width="6"
                        stroke-linecap="round"
                        stroke-miterlimit="10"
                        points="100.2,40.2 51.5,88.8 29.8,67.5 "
                      />
                    </svg>  
                  </div>
                  <h3 class="text-success mx-auto mt-3">ดำเนินการสำเร็จ</h3> 
                </div>
                
                <div class="modal-body p-2" v-if="!isSuccess">
                  <div class="col-8 mx-auto">
                    <svg
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 131 131"
                      width="100%"
                      height="100%"
                    >
                      <circle
                        class="circle"
                        fill="none"
                        stroke="#db3646"
                        stroke-width="6"
                        stroke-miterlimit="10"
                        cx="65.1"
                        cy="65.1"
                        r="62.1"
                      />
                      <line
                        class="line1"
                        fill="none"
                        stroke="#db3646"
                        stroke-width="6"
                        stroke-linecap="round"
                        stroke-miterlimit="10"
                        x1="34.4"
                        y1="37.9"
                        x2="95.8"
                        y2="92.3"
                      />
                      <line
                        class="line2"
                        fill="none"
                        stroke="#db3646"
                        stroke-width="6"
                        stroke-linecap="round"
                        stroke-miterlimit="10"
                        x1="95.8"
                        y1="38"
                        x2="34.4"
                        y2="92.2"
                      />
                    </svg>
                  </div>
                  <h3 class="text-danger text-center mt-3">ดำเนินการล้มเหลว</h3> 
                </div>
                <button type="button" class="btn-close" v-if="!isSuccess" @click="closeNotification" style="position: absolute; right: 20px; top: 20px;"></button>
              </div>
            </div>
            <div class="modal-body d-flex align-items-center justify-content-center">
              <div class="text-center">
                <div v-if="isSuccess">
                  <h5 class="text-success">ระบบได้ทำการปิดประกาศแล้วเรียบร้อย</h5>
                </div>
                <div v-else>
                  <h5 class="text-danger">ปิดประกาศล้มเหลว</h5>
                  <h5 class="text-danger">หากพบปัญหากรุณาติดต่อแอดมิน</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script>
import axios from '@/axios.js';
export default {
  data() {
    return {
      items: [],
      currentPage: 1,
      itemsPerPage: 10,
      selectedPropertyId: null,
      closeReason: 'sold', // ค่าเริ่มต้นเป็น 'sold'
      showModal: false,
      showNotification: false,
      
      // ตัวแปรสำหรับเก็บค่าการค้นหา
      searchFilters: {
            Category: '',
            PropertyType: '',
            Region: '',
            Province: '',
            Amphur: '',
            Tambon: '',
            MinPrice: '',
            MaxPrice: '',
        },
        categories: ['บ้าน', 'คอนโด', 'ที่ดิน', 'ทาวน์โฮม', 'อาคารพาณิชย์', 'อสังหาอื่นๆ'], // เพิ่มประเภทอสังหา
        propertyTypes: ['ขาย', 'เช่า'], // เพิ่มประเภทประกาศ
    };
  },
  computed: {
    // คำนวณรายการที่จะแสดงในหน้านั้นๆ
    paginatedItems() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.items.slice(start, end);
    },
    // จำนวนหน้าทั้งหมด
    totalPages() {
      return Math.ceil(this.items.length / this.itemsPerPage);
    },
  },
  mounted() {
    this.fetchProperties();
  },
  methods: {
    async fetchProperties() {
      
        // ตั้งค่า Axios ให้ส่ง Cookie ไปด้วย
        let params = {};
        for (let key in this.searchFilters) {
            if (this.searchFilters[key] !== '') {
                params[key] = this.searchFilters[key];
            }
        }
        console.log(params)
        try {
          const response = await axios.get('/api/property', {
              withCredentials: true,
              params: params,
          });

          this.items = response.data.map(property => ({
            id: property._id,
            image: property.images[0]?.url || 'https://via.placeholder.com/150',
            title: property.Title,
            propertyType: property.PropertyType,
            category: property.Category,
            rai: property.Scale.ScaleRai || 0,
            ngan: property.Scale.ScaleNgan || 0,
            wah: property.Scale.ScaleWah || 0,
            description: property.Detail,
            price: property.Price.toLocaleString() + ' บาท',
            status: property.status || 'active',
            SelectedCard: property.SelectedCard,
          }));
          this.currentPage = 1;
      } catch (error) {
        console.error('Error fetching properties:', error);
      }
    },
    updateFilters() {
      this.fetchProperties();
    },
    goToEditProperty(propertyId) {
      this.$emit('edit-property', propertyId);
    },
    openCloseModal(propertyId) {
      this.selectedPropertyId = propertyId;
      this.closeReason = 'sold'; // ตั้งค่าเริ่มต้น
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    closeNotification() {
      this.showNotification = false; // ปิด modal แจ้งเตือน
    },
    async confirmClose() {
      try {
        const propertyId = this.selectedPropertyId;
        const reason = this.closeReason;

        // ส่งคำขอไปยัง Backend เพื่อปิดประกาศ
        const response = await axios.put(`/api/property/close/${propertyId}`, { reason }, { withCredentials: true });

        if (response.status === 200) {
          // ปิดโมดอล
          this.closeModal();
          // อัปเดตรายการประกาศ
          this.fetchProperties()
          this.isSuccess = true;
          this.showNotification = true;
          setTimeout(() => {
            this.showNotification = false;
            this.isSuccess = false;
          }, 1500);
        } else {
          this.isSuccess = false;
          this.showNotification = true;
        }
      } catch (error) {
        console.error('Error closing property:', error);
        alert('เกิดข้อผิดพลาดในการปิดประกาศ');
      }
    },
    async bumpProperty(propertyId) {
      try {
        // ส่งคำขอไปยัง Backend เพื่อเลื่อนประกาศ
        await axios.put(`/api/property/bump/${propertyId}`, {}, { withCredentials: true });

        // อัปเดตรายการประกาศ
        await this.fetchProperties();

        alert('เลื่อนประกาศเรียบร้อยแล้ว');
      } catch (error) {
        console.error('Error bumping property:', error);
        alert('เกิดข้อผิดพลาดในการเลื่อนประกาศ');
      }
    },
    previousPage() {
        if (this.currentPage > 1) {
            this.currentPage--;
            this.scrollToTop();
        }
    },
    nextPage() {
        if (this.currentPage < this.totalPages) {
            this.currentPage++;
            this.scrollToTop();
        }
    },
    scrollToTop() {
        // เลื่อนหน้าจอไปยังด้านบนสุดของหน้ารายการ
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // ใช้การเลื่อนแบบนุ่มนวล
        });
    },
  },
};
</script>

  
<style scoped>
/* ทำให้รูปภาพไม่เกินขนาดคอนเทนเนอร์ */
img {
  max-width: 100%;
  height: 210px;
  object-fit: cover;
}

.border-top-none { 
  border-top: 0rem;
  border-radius: 15px;
}

.card {
  overflow: hidden;
}

.price {
  font-weight: bold;
  font-size: 18px;
}

.badge {
  font-size: 14px;
  font-weight: 400;
  padding: 0.4rem 0.8rem;
  border-radius: 10px;
}

.button-group button {
  font-size: 14px;
}

/* สไตล์สำหรับโมดอล */
.modal {
  font-family: 'Prompt', sans-serif;
}

.modal-content {
  border: none;
  box-shadow: none;
}

/* สำหรับ Backdrop */
.backdrop-enter-active,
.backdrop-leave-active {
  transition: opacity 0.3s ease;
}

.backdrop-enter-from,
.backdrop-leave-to {
  opacity: 0;
}

/* สำหรับ Modal */
.modal-enter-active,
.modal-leave-active {
  transition: all 0.3s ease;
}

.modal-enter-from,
.modal-leave-to {
  opacity: 0;
  transform: translateY(-50px);
}

/* ปรับขนาด Modal สำหรับหน้าจอคอมพิวเตอร์ */
.custom-modal-dialog {
  max-width: 600px; 
}

/* ปรับการจัดวางของการ์ดบนหน้าจอเล็ก */
@media (max-width: 768px) {
  .row.g-0 {
    flex-direction: column;
  }
  img {
    height: auto;
  }
  .card-footer {
    flex-direction: column;
    align-items: flex-start;
  }
  .card-footer .btn {
    margin-top: 10px;
    width: 100%;
  }
  .b-m {
    display: block;
    border-radius: 5px;
    margin-bottom: 10px; /* ระยะห่างระหว่างบรรทัด */
  }
}

/* ปรับขนาดตัวอักษรบนหน้าจอเล็ก */
@media (max-width: 576px) {
  .email-text span {
    font-size: 13px;
  }
}

/* เพิ่มไอคอนและเอฟเฟกต์ให้ modal */
.bi-check-circle-fill {
  font-size: 2rem;
}
.bi-x-circle-fill {
  font-size: 2rem;
}
.checkmark-svg {
  transform: scale(0); /* เริ่มจากขนาดเล็ก */
  opacity: 0;
  transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

.modal-enter-active .checkmark-svg {
  transform: scale(1); /* ขยายขึ้นสู่ขนาดปกติ */
  opacity: 1;
}

.circle {
  stroke-dasharray: 391;
  stroke-dashoffset: 391;
  animation: draw-circle 0.5s forwards;
}
.check {
  stroke-dasharray: 200;
  stroke-dashoffset: 200;
  animation: draw-check 0.5s forwards 0.5s;
}
@keyframes draw-circle {
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes draw-check {
  to {
    stroke-dashoffset: 0;
  }
}

:deep(.circle) {
  stroke-dasharray: 391;
  stroke-dashoffset: 391;
  animation: draw-circle 0.5s forwards;
}

:deep(.line1),
:deep(.line2) {
  stroke-dasharray: 85;
  stroke-dashoffset: 85;
  animation: draw-lines 0.5s forwards 0.5s;
}

@keyframes draw-circle {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes draw-lines {
  to {
    stroke-dashoffset: 0;
  }
}

.custom-modal-content svg {
  max-width: 120px;
  max-height: 120px;
}
</style>

  