<template>
  <button class="btn btn-outline-light set-position m-0" @click="openModal">{{ buttonLabel }}</button>

  <teleport to="body">
    <!-- Backdrop -->
    <transition name="backdrop">
      <div
        v-if="showModal"
        class="modal-backdrop fade show"
        @click="closeModal"
      ></div>
    </transition>

    <!-- Modal -->
    <transition name="modal">
      <div
        v-if="showModal"
        class="modal fade show"
        tabindex="-1"
        role="dialog"
        style="display: block;"
      >
        <div
          v-if="!isRegisterMode"
          class="modal-dialog custom-modal-dialog"
          role="document"
        >
          <div class="modal-content custom-modal-content">
            <!-- Modal Header -->
            <div class="modal-header">
              <div class="mx-auto">
                <h5 class="modal-title p-2">เข้าสู่ระบบ</h5>
                  <button type="button" class="btn-close" @click="closeModal" style="position: absolute; right: 20px; top: 20px;"></button>
              </div>
            </div>
            <!-- Modal Body -->
            <div class="modal-body">
              <div class="text-center">
                <img src="img/newlogo.png" alt="logo" class="img-fluid responsive-img mb-4">
                
                <!-- แสดงข้อความแจ้งเตือนการล็อกอิน -->
                <div v-if="loginError" class="alert alert-danger mt-3" role="alert">
                  {{ loginError }}
                </div>

                <div class="container px-md-5">
                  <form @submit.prevent="handleSubmit">
                    <div class="row text-start">
                      <label for="username" class="col-sm-3 col-form-label">ชื่อผู้ใช้</label>
                    </div>
                    <div class="mb-3 row">
                      <div class="col-sm-12">
                        <input v-model="username" type="text" class="form-control" id="username" placeholder="ชื่อผู้ใช้หรืออีเมล์" required>
                      </div>
                    </div>

                    <div class="row text-start">
                      <label for="password" class="col-sm-3 col-form-label">รหัสผ่าน</label>
                    </div>
                    <div class="mb-3 row">
                      <div class="col-sm-12">
                        <div class="input-group">
                          <input v-model="password" :type="passwordVisible ? 'text' : 'password'" class="form-control" id="pwlogin" placeholder="รหัสผ่าน" required>
                          <div class="input-group-append">
                            <button
                              type="button"
                              class="btn btn-outline-secondary"
                              @click="togglePasswordVisibility"
                            >
                              <i
                                :class="
                                  passwordVisible ? 'far fa-eye-slash' : 'far fa-eye'
                                "
                              ></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="mb-3 row">
                      <div class="text-center">
                        <button type="submit" class="btn btn-primary col-12">เข้าสู่ระบบ</button>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <div class="text-center">
                        <button type="button" class="btn btn-outline-secondary col-12" @click="switchToRegister">สมัครสมาชิก</button>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <div class="col d-flex justify-content-between">
                        <a href="/contact" class="text-decoration-none">ลืมรหัสผ่าน?</a>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <!-- Modal Footer -->
            <div class="modal-footer justify-content-center">
              <p>
                ยังไม่มีบัญชี?
                <a href="#" @click="switchToRegister" class="text-primary">สมัครสมาชิก</a>
              </p>
            </div>
          </div>
        </div>
        <div v-else class="modal-dialog custom-modal-dialog" role="document">
          
            <div class="modal-content custom-modal-content">
              <!-- Modal Header -->
              <div class="modal-header">
                <div class="mx-auto">
                  <h5 class="modal-title p-2">สมัครสมาชิก</h5>
                  <button type="button" class="btn-close" @click="closeModal" style="position: absolute; right: 20px; top: 20px;"></button>
                  <button type="button" class="btn btn-outline-secondary" @click="switchToLogin" style="position: absolute; left: 20px; top: 20px;"><i class="fas fa-chevron-left"></i> กลับ</button>
                </div>
              </div>
              <!-- Modal Body -->
              <div class="modal-body my-auto">
                <div class="d-flex align-items-center justify-content-center">
                  <div class="text-center">
                    <img src="img/newlogo.png" alt="logo" class="img-fluid responsive-img mb-4">
                    
                    <!-- แสดงข้อความแจ้งเตือนการล็อกอิน -->
                    <!-- <div v-if="loginError" class="alert alert-danger mt-3" role="alert">
                      {{ loginError }}
                    </div> -->
                    <div class="container px-md-5">
                      <form id="formRegister" @submit.prevent="form_submit" method="post">
                        <div v-if="this.registerStatus" class="alert alert-success">
                          {{ error }}
                        </div>
                        <div v-else>
                          <div v-if="error" class="alert alert-danger">
                            {{ error }}
                          </div>
                        </div>
                        <div class="row text-start">
                          <label for="username" class="col-sm-3 col-form-label">ชื่อผู้ใช้<span class="text-danger font-weight-bold"> *</span></label>
                        </div>
                        <div class="mb-3 row">
                          <div class="col-sm-12">
                            <input v-model.trim="formData.username" name="username" type="text" class="form-control" id="username" placeholder="ชื่อผู้ใช้" required>
                          </div>
                        </div>
                        <div class="row text-start">
                          <label for="email" class="col-sm-3 col-form-label">อีเมล์<span class="text-danger font-weight-bold"> *</span></label>
                        </div>
                        <div class="mb-3 row">
                          <div class="col-sm-12">
                            <input v-model.trim="formData.email" name="email" type="email" class="form-control" id="email" placeholder="อีเมล์" required>
                          </div>
                        </div>
                        <div class="row text-start">
                          <label for="password" class="col-sm-3 col-form-label">รหัสผ่าน<span class="text-danger font-weight-bold"> *</span></label>
                        </div>
                        <div class="mb-3 row">
                          <div class="col-sm-12">
                            <div class="input-group">
                              <input v-model.trim="formData.password" name="password" type="password" class="form-control" id="pwregister" minlength="6" placeholder="รหัสผ่าน" required>
                              <div class="input-group-append">
                                <button
                                  type="button"
                                  class="btn btn-outline-secondary"
                                  @click="togglePasswordVisibility"
                                >
                                  <i
                                    :class="
                                      passwordVisible ? 'far fa-eye-slash' : 'far fa-eye'
                                    "
                                  ></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row text-start">
                          <label for="pwconfirm" class="col-sm-4 col-form-label">ยืนยันรหัสผ่าน<span class="text-danger font-weight-bold"> *</span></label>
                        </div>
                        <div class="mb-3 row">
                          <div class="col-sm-12">
                            <div class="input-group">
                              <input v-model.trim="formData.pwconfirm" name="pwconfirm" type="password" class="form-control" id="pwconfirm" minlength="6" placeholder="ยืนยันรหัสผ่าน" required>
                              <div class="input-group-append">
                                <button
                                  type="button"
                                  class="btn btn-outline-secondary"
                                  @click="togglePasswordVisibility"
                                >
                                  <i
                                    :class="
                                      passwordVisible ? 'far fa-eye-slash' : 'far fa-eye'
                                    "
                                  ></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="alert alert-primary">
                          <i class="bi bi-info-circle"></i> รหัสผ่านอย่างน้อย 6 ตัวขึ้นไป
                        </div>

                        <div class="mt-4 mb-3 row">
                          <div class="text-center">
                              <button type="submit" class="btn btn-primary col-12">สมัครสมาชิก</button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Modal Footer -->
              <div class="modal-footer justify-content-center">
                <a href="/contact" class="text-decoration-none">ลืมรหัสผ่าน?</a>
              </div>
            </div>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    buttonLabel: {
      type: String,
      default: 'เข้าสู่ระบบ | สมัครสมาชิก', // ค่าเริ่มต้นคือ 'เข้าสู่ระบบ'
    },
  },
  data() {
    return {
      showModal: false,
      username: '',
      password: '',
      passwordVisible: false,
      loginError: '',
      isRegisterMode: false,
      /*ส่วนสมัครสมาชิก*/
      formData: {
        username: '',
        email: '',
        password: '',
        pwconfirm: ''
      },
      error: null,
      registerStatus: false,
    };
  },
  methods: {
    ...mapActions([
      'login', // นำเข้า action 'login' จาก Vuex
      'register',
    ]),
    togglePasswordVisibility() {
      this.passwordVisible = !this.passwordVisible;
    },
    openModal() {
      this.showModal = true;
      document.body.classList.add('modal-open');
      document.documentElement.style.overflow = 'hidden'; // บังคับให้ปิดการเลื่อนที่ <html>
    },

    closeModal() {
      this.showModal = false;
      this.isRegisterMode = false;

      // รีเซ็ตค่าในฟอร์ม login
      this.username = '';
      this.password = '';
      this.passwordVisible = false;
      this.loginError = '';

      // รีเซ็ตค่าในฟอร์ม register
      this.formData.username = '';
      this.formData.email = '';
      this.formData.password = '';
      this.formData.pwconfirm = '';
      this.error = null;
      setTimeout(() => {
        document.body.classList.remove('modal-open');
        document.documentElement.style.overflow = ''; // เปิดการเลื่อนที่ <html> หลังปิดโมดอล
      }, 300);
    },
    async form_submit() {
      this.error = null;
      if (this.formData.password !== this.formData.pwconfirm) {
        this.error = 'รหัสผ่านไม่ตรงกัน';
        this.formData.password = '';
        this.formData.pwconfirm = '';
        this.$nextTick(() => {
          this.$refs.password.focus();
        });
        return;
      }

      try {
        await this.register({
          username: this.formData.username,
          email: this.formData.email,
          password: this.formData.password
        });
        // รีเซ็ตค่าในฟอร์ม register
        this.formData.username = '';
        this.formData.email = '';
        this.formData.password = '';
        this.formData.pwconfirm = '';
        this.registerStatus = true;
        this.error = 'สมัครสมาชิกสำเร็จ';
        setTimeout(() => {
          this.registerStatus = false;
          this.error = '';
        }, 3000);
        // setTimeout(() => {
        //   document.body.classList.remove('modal-open');
        //   document.documentElement.style.overflow = ''; // เปิดการเลื่อนที่ <html> หลังปิดโมดอล
        // }, 300);
        // window.location.reload();
      } catch (error) {
        this.error = 'ชื่อผู้ใช้หรืออีเมล์นี้ถูกใช้ไปแล้ว';
      }
    },
    async handleSubmit() {
      try {
        this.loginError = ''; // รีเซ็ตค่า loginError ก่อนเริ่มการล็อกอิน
        const response = await this.login({
          username: this.username,
          password: this.password,
        });

        if (response.message === 'Logged in successfully') {
          this.loginError = 'ล็อกอินสำเร็จ';
          this.closeModal();
        } else {
          throw new Error(response.message || 'Login failed');
        }
      } catch (error) {
        this.loginError = 'ล็อกอินไม่สำเร็จ กรุณาตรวจสอบชื่อผู้ใช้และรหัสผ่าน';
      }
    },
    switchToRegister() {
      this.isRegisterMode = true;
      // รีเซ็ตค่าในฟอร์ม login
      this.username = '';
      this.password = '';
      this.passwordVisible = false;
      this.loginError = '';
      this.registerStatus = false;
    },
    switchToLogin() {
      this.isRegisterMode = false;
      // รีเซ็ตค่าในฟอร์ม register
      this.formData.username = '';
      this.formData.email = '';
      this.formData.password = '';
      this.formData.pwconfirm = '';
      this.error = null;
      this.registerStatus = false;
    },
    
  }
};
</script>

<style scoped>
.modal {
    font-family: 'Prompt', sans-serif;
}
.modal-content {
  border: none;
  box-shadow: none;
  background-color: #2d1f65 !important;
  color: white;
  height: 100%;
  /* ลบ height: 100%; และ display: flex; */
}
/* สำหรับ Backdrop */
.backdrop-enter-active,
.backdrop-leave-active {
  transition: opacity 0.3s ease;
}
.backdrop-enter-from,
.backdrop-leave-to {
  opacity: 0;
}

/* สำหรับ Modal */
.modal-enter-active,
.modal-leave-active {
  transition: all 0.3s ease;
}
.modal-enter-from,
.modal-leave-to {
  opacity: 0;
  transform: translateY(-50px);
}

/* ปรับขนาด Modal สำหรับหน้าจอคอมพิวเตอร์ */
.custom-modal-dialog {
  max-width: 600px; /* เพิ่มขนาดเป็น 2 เท่า */
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}
.set-position {
  margin-right: 1rem;
}

/* ปรับขนาด Modal สำหรับมือถือ */
@media (max-width: 768px) { /* ขยายขอบเขตจาก 576px เป็น 768px */
  .custom-modal-dialog {
    width: 100%;
    max-width: 100%;
    margin: 0;
    height: 100%;
  }
  .custom-modal-content {
    border-radius: 0;
  }
  .modal {
    padding: 0;
    margin: 0;
    overflow-y: auto; /* ให้โมดอลสามารถเลื่อนเนื้อหาได้ */
    height: 100%; /* ตั้งความสูงของโมดอลเป็น 100% ของหน้าจอ */
  }

  .modal-dialog {
    width: 100%;
    max-width: none;
    margin: 0;
    height: 100%;
  }

  /* ปรับขนาดรูปภาพให้ใหญ่ขึ้นบนมือถือ */
  .modal-body .responsive-img {
    width: 50% !important;
    height: auto !important;
  }
  .modal-body {
    padding: 1rem;
  }
  .modal-footer {
    background-color: #2d1f65 !important;
  }
  .modal-header,
  .modal-footer {
    padding: 1rem;
  }
  /* ปรับ margin-bottom ให้ทำงาน */
  .modal-body .mb-4 {
    margin-bottom: 1.5rem !important;
  }
  .set-position {
    margin-right: 0;
  }
  .responsive-img {
    width: 50% !important;
    height: auto !important;
  }
}
/* ป้องกันการเลื่อนหน้าเว็บด้านหลังเมื่อโมดอลเปิดอยู่ */
.modal-open, .modal-open html {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
}
.modal {
  overflow-y: auto !important; /* ให้เลื่อนเฉพาะโมดอลเท่านั้น */
}
/* ปรับขนาดรูปภาพสำหรับหน้าจออื่น ๆ */
.responsive-img {
  width: 30%;
  height: auto;
  display: block;
  margin: 0 auto;
  margin-bottom: 1.5rem; /* เพิ่มระยะห่างด้านล่าง */
}

/* ปรับสไตล์อื่น ๆ ตามที่คุณต้องการ */
.input-group-append button {
  border-radius: 0 0.25rem 0.25rem 0;
}

button.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}

.modal-content {
  border: none;
  box-shadow: none;
  background-color: #2d1f65;
  color: white;
}

.modal-body {
  background-color: #2d1f65; /* หากต้องการให้เฉพาะเนื้อหาภายในโมดาลมีสีพื้นหลังนี้ */
  color: white; /* เปลี่ยนสีตัวอักษรให้เป็นสีขาวเพื่อให้มองเห็นได้ชัด */
}
.modal-title, .modal-body, .modal-footer p, label {
  color: white; /* เปลี่ยนสีข้อความเป็นสีขาว */
}
.modal-open {
  overflow: hidden;
}

.btn-primary {
  background-color: white; /* เปลี่ยนสีปุ่ม */
  color: #2d1f65; /* เปลี่ยนสีข้อความในปุ่ม */
}

.btn-outline-secondary {
  border-color: white; /* เปลี่ยนสีขอบปุ่ม */
  color: white; /* เปลี่ยนสีข้อความในปุ่ม */
}

  #pwconfirm:invalid,
  #pwregister:invalid {
    border-color: salmon;
    background-color: rgb(252, 230, 228);
  }

  #pwconfirm:valid,
  #pwregister:valid {
    border-color: green;
    background-color: rgb(229, 253, 229);
  }
</style>